import React, {useEffect, useState} from 'react';
import {
  Flex,
  Box,
  DSButton as Button,
  Link,
  Icon,
  HStack,
  PrivacySettings,
  Text,
  ModalBody,
  Tag,
  Divider,
  SharePointIcon,
} from 'spekit-ui';

import {CustomFieldsEditor} from '../../Content/CustomFieldsEditor';
import {TopicSelector} from '../../TopicSelector';
import {IOptionType, TCustomFieldValues, IFileOrFolder} from 'spekit-types';
import {RiAddCircleLine, RiCloseLine, RiDriveLine} from 'react-icons/ri';
import {CONTENT_SHARING} from 'spekit-datalayer';

import {mimeTypeToGraphicType, getFileTagFromMimeType} from '../constants';
import {getFriendlyFileSize} from '../../utils';
import {isDefaultMimeTypePresent} from '../helpers';
import {IconType} from 'react-icons';

interface IProps {
  files: IFileOrFolder[];
  onChange: (metadata: IMetadata) => void;
  onRemove: (fileId: string) => void;
}

export interface IMetadata {
  selectedTopics?: string[];
  customFields?: TCustomFieldValues;
  privacyIsChecked?: boolean;
}

export const MetadataForm = (props: IProps) => {
  const {files, onChange, onRemove} = props;

  const [selectedTopics, setSelectedTopics] = useState<IOptionType[]>([]);
  const [privacyIsChecked, setPrivacyIsChecked] = useState(false);
  const [customFields, setCustomFields] = useState<TCustomFieldValues>({});

  useEffect(() => {
    const metadata = {
      selectedTopics: selectedTopics.map((topic) => topic.value),
      customFields,
      privacyIsChecked,
    };

    onChange(metadata);
  }, [customFields, privacyIsChecked, selectedTopics]);

  const handleRemove = (file: IFileOrFolder) => () => {
    onRemove(file.id);
  };

  return (
    <ModalBody my={24}>
      <Flex
        gap={16}
        direction='column'
        data-testid='files-list'
        maxH='340px'
        overflowY='auto'
      >
        {files.map((file, i) => (
          <Flex
            key={i}
            p={16}
            align='center'
            bg='neutral.25'
            border='1px solid'
            borderColor='neutral.100'
            borderRadius={6}
            flex={1}
            data-testid={`${file.name}-file-row`}
            justify='space-between'
          >
            <Text fontWeight={600} variant='body2' wordBreak='break-word'>
              {file.name}
            </Text>

            <Flex align='center'>
              <Tag
                mr={10}
                leftIcon={
                  isDefaultMimeTypePresent(file.fields.mimeType)
                    ? (SharePointIcon as IconType)
                    : RiDriveLine
                }
                variant='status'
                colorScheme={mimeTypeToGraphicType(file.fields.mimeType)}
                data-testid={getFileTagFromMimeType(file.fields.mimeType)}
              >
                {getFileTagFromMimeType(file.fields.mimeType)}
              </Tag>
              <Flex
                mr={16}
                h='32px'
                direction='column'
                justifyContent='center'
                alignItems='center'
                bg='white'
                borderRadius={2}
                borderWidth={1}
                borderStyle='solid'
                borderColor='neutral.300'
                minW='64px'
                data-testid='file-size'
              >
                <Text variant='caption1' fontWeight='bold'>
                  {getFriendlyFileSize(Number(file.fields.size))}
                </Text>
              </Flex>

              <Button
                data-testid='remove-file-btn'
                icon={<Icon as={RiCloseLine} />}
                disabled={files.length === 1}
                aria-label='remove file'
                colorScheme='transparent'
                variant='icon'
                size='medium'
                onClick={handleRemove(file)}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Divider borderColor='neutral.200' my={24} />

      <HStack align='end'>
        <Box width='100%'>
          <TopicSelector
            placeholder='Select Topics'
            label='Add to Topics'
            defaultValues={selectedTopics}
            handleTopicsChange={(newValue) => {
              // typecasting here because <Select /> generics are messed up
              setSelectedTopics(newValue as IOptionType[]);

              //setIsTopicError(false);
            }}
            isInvalid={false}
            allowedOnlyTopics
            isDisabled={false}
            blurInputOnSelect
          />
        </Box>
        <Button
          data-testid='create-topic-btn'
          href={`/app/wiki/topics/create`}
          as={Link}
          colorScheme='primary'
          leftIcon={<Icon h='18px' w='18px' as={RiAddCircleLine} />}
          size='large'
          variant='ghost'
          target='_blank'
        >
          Create Topic
        </Button>
      </HStack>

      <Box mt={32}>
        <CustomFieldsEditor
          mode='create'
          values={customFields}
          updateValues={(values) => setCustomFields(values)}
          type='file'
        />
      </Box>

      <Box mt={32}>
        <PrivacySettings
          variant='asset'
          alertMessage='Existing external links for this content will no longer be accessible.'
          data-testid='asset-external-share-checkbox'
          isChecked={privacyIsChecked}
          onChange={() => {
            setPrivacyIsChecked(!privacyIsChecked);
          }}
          isDisabled={false}
        >
          {CONTENT_SHARING.ALL_USERS}
        </PrivacySettings>
      </Box>
    </ModalBody>
  );
};

export default MetadataForm;
