import React, {useCallback, useEffect, useState} from 'react';
import {RiCloseLine, RiUploadLine, RiDriveLine} from 'react-icons/ri';
import {IAsset, TAssetExtension, TAssetMimeType, TContentIntegration} from 'spekit-types';
import {
  DSButton as Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Spinner,
  Tag,
  Text,
} from 'spekit-ui';
import {MAX_FILE_LABEL_CHARACTERS} from '../constants';
import {getDocumentTypeFromExtension, getFileTag} from 'spekit-datalayer';

interface IPropsFilePreview {
  fileName: string;
  fileSize: number; // size in bytes
  fileExtension: TAssetExtension;
  isUploading?: boolean;
  hasUploaded?: boolean;
  errorMessage?: string;
  warnMessage?: string;
  store?: 'native' | TContentIntegration;
  onChange: (fileName: string) => void;
  onRemove?: () => void;
  onEditFile?: () => void;
  onBlur?: (fileName: string) => void;
  showLoaderOnly?: boolean;
  contentType?: TAssetMimeType;
}

const getFriendlyFileSize = (fileSizeInBytes: number, decimalPlaces: number = 1) => {
  const fileSizeInKB = fileSizeInBytes / 1024;
  const fileSizeInMB = fileSizeInKB / 1024;

  if (fileSizeInMB >= 1) {
    return `${fileSizeInMB.toFixed(decimalPlaces)}MB`;
  }

  return `${fileSizeInKB.toFixed(decimalPlaces)}KB`;
};

export const FilePreview = ({
  isUploading = true,
  hasUploaded = false,
  store = 'native',
  warnMessage,
  errorMessage,
  fileName,
  fileSize,
  fileExtension,
  onChange,
  onRemove,
  onEditFile,
  onBlur,
  showLoaderOnly,
  contentType,
}: IPropsFilePreview) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [helperText, setHelperText] = useState('');

  const documentType = getDocumentTypeFromExtension(fileExtension) || 'primary';

  const fileDetails = {
    file_name: fileName,
    file_size: fileSize,
    content_type: contentType || 'application/pdf',
    store,
  } as IAsset;

  useEffect(() => {
    if (warnMessage?.length) {
      setIsInvalid(true);
      setHelperText('');
      return;
    }
    setIsInvalid(false);
  }, [warnMessage]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    isInvalid === false && onBlur && onBlur(fileName);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    onChange(value);
  };

  const validate = useCallback(
    (value: string) => {
      !warnMessage && setIsInvalid(false);

      if (value.length > MAX_FILE_LABEL_CHARACTERS) {
        setIsInvalid(true);
        setHelperText(
          `File name cannot be longer than ${MAX_FILE_LABEL_CHARACTERS} characters.`
        );
      }

      if (
        value.length === 0 ||
        (value.length > 0 && value.replace(/\s/g, '').length == 0)
      ) {
        setIsInvalid(true);
        setHelperText('File name cannot be empty.');
      }
    },
    [fileName]
  );

  useEffect(() => {
    validate(fileName);
  }, [validate]);

  if (errorMessage) {
    return (
      <Flex
        alignItems='center'
        gap={10}
        p={16}
        bg='error.25'
        border='1px solid'
        borderColor='error.600'
        borderRadius={6}
      >
        <Tag colorScheme={documentType} variant='status' data-testid='asset-tag'>
          {getFileTag(fileDetails)}
        </Tag>
        <Flex flex={1} gap={4} justifyContent='space-between' alignItems='center'>
          <Text>{fileName}</Text>
          <Text variant='body2' fontWeight={400} color='error.700'>
            {errorMessage}
          </Text>
        </Flex>
        <Button
          icon={<Icon as={RiCloseLine} />}
          aria-label='remove file'
          colorScheme='transparent'
          variant='icon'
          size='medium'
          onClick={onRemove}
        />
      </Flex>
    );
  }

  return (
    <Flex
      alignItems='center'
      gap={10}
      p={16}
      bg='neutral.25'
      border='1px solid'
      borderColor='neutral.100'
      borderRadius={6}
      flex={1}
    >
      <Tag
        leftIcon={store !== 'native' ? RiDriveLine : undefined}
        colorScheme={documentType}
        variant='status'
        data-testid='asset-tag'
      >
        {getFileTag(fileDetails)}
      </Tag>
      {hasUploaded || isUploading ? (
        <Flex flex={1} justifyContent='space-between' alignItems='center'>
          <Text wordBreak='break-word'>{fileName}</Text>
          <Flex gap={2} alignItems='center'>
            {!showLoaderOnly && (
              <Text
                variant='body2'
                fontWeight={400}
                color='primary.500'
                lineHeight='22px'
              >
                {hasUploaded ? 'Uploaded' : 'Uploading'}
              </Text>
            )}
            {!hasUploaded && (
              <Spinner
                color='primary.500'
                emptyColor='neutral.200'
                size='sm'
                speed='1.65s'
                thickness='2px'
              />
            )}
          </Flex>
        </Flex>
      ) : (
        <>
          <FormControl isInvalid={isInvalid}>
            <Input
              data-testid='input-filename'
              value={fileName}
              onChange={handleChange}
              onBlur={handleBlur}
              isDisabled={store !== 'native'}
              onKeyDown={(e) => {
                // the listener in contentScript recognizes the event target from here as a div, which prevents the filter from working
                // we simply prevent the propagation of the event to fix this from the asset edit modal
                if (e.key === 's') {
                  e.stopPropagation();
                }
              }}
              colorScheme={warnMessage ? 'warning' : 'error'}
            />

            {isInvalid && (
              <FormErrorMessage data-testid='input-error'>{helperText}</FormErrorMessage>
            )}
            {isInvalid && warnMessage && (
              <FormErrorMessage data-testid='duplicate-warning' colorScheme='warning'>
                {warnMessage}
              </FormErrorMessage>
            )}
          </FormControl>

          <Flex
            h='32px'
            direction='column'
            justifyContent='center'
            alignItems='center'
            bg='white'
            borderRadius={2}
            borderWidth={1}
            borderStyle='solid'
            borderColor='neutral.300'
            minW='64px'
            data-testid='file-size'
          >
            <Text variant='caption1' fontWeight='bold'>
              {getFriendlyFileSize(fileSize)}
            </Text>
          </Flex>
        </>
      )}
      {onRemove ? (
        <Button
          data-testid={'remove-file-btn'}
          icon={<Icon as={RiCloseLine} />}
          aria-label={'remove file'}
          colorScheme='transparent'
          variant='icon'
          size='medium'
          onClick={onRemove}
        />
      ) : store === 'native' ? (
        <Button
          data-testid={'edit-file-btn'}
          icon={<Icon as={RiUploadLine} />}
          aria-label={'edit file'}
          colorScheme='transparent'
          disabled={store !== 'native'}
          variant='icon'
          size='medium'
          onClick={onEditFile}
        />
      ) : null}
    </Flex>
  );
};
